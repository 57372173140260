import {
  Address,
  DynamicUIComponent,
  GetPhraseFunction,
  UIBlueprint,
} from "@frankieone/shared";
import {
  mkAUSAddressBlueprint,
  mkNZLAddressBlueprint,
} from "./english-group-blueprint";
import { mkLongFormAddressBlueprint } from "./longform-blueprint";
import { mkSGPAddressBlueprint } from "./sgp-blueprint";
import { WIDTH_SIZES } from "@/plugins/initialiser";

/**
 * Address blueprints rely on two variables to store state "buffer" and "completeAddress"
 * Buffer is updated while the address is incomplete. once it's complete, both buffer and completeAddress are updated
 */
export type Context = {
  getWidthClass: () => WIDTH_SIZES;
  getCompleteAddress: () => Address;
  getAcceptedCountries: () => Country[];
  setCompleteAddress: (address: Address) => void;
  getPhrase: GetPhraseFunction;
  setBuffer: (a: Address) => void;
  getBuffer: () => Address;
  nzLocalityDropdown: boolean;
};
export function setup(context: Context) {
  const { setBuffer, getCompleteAddress, getAcceptedCountries } = context;
  const address = getCompleteAddress();
  const acceptedCountries = getAcceptedCountries();
  const singleCountryOption = acceptedCountries.length === 1;
  const selectedCountry = address.country;
  const [singleCountryCode] = acceptedCountries;

  if (singleCountryOption && !selectedCountry)
    address.country = singleCountryCode;
  setBuffer(address);
}
export function mkManualAddressBlueprint(context: Context): UIBlueprint {
  const { getAcceptedCountries, getBuffer } = context;
  const acceptedCountries = getAcceptedCountries();
  const countriesCount = acceptedCountries.length;
  const address = getBuffer();
  const { country } = address;

  const children = {};
  if (countriesCount > 1)
    children["country"] = mkCountryInputComponent(context);
  if (country) children["address-fields"] = mkAddressBlueprint(context);

  return new UIBlueprint({
    root: new DynamicUIComponent({
      tag: "div",
      classes: ["ff-address-manual-input"],
    }),
    children,
  });
}
function mkAddressBlueprint(context: Context): UIBlueprint {
  const { getBuffer } = context;
  const address = getBuffer();
  const { country } = address;

  if (country === "AUS") return mkAUSAddressBlueprint(context);
  if (country === "NZL") return mkNZLAddressBlueprint(context);
  if (country === "SGP") return mkSGPAddressBlueprint(context);
  else return mkLongFormAddressBlueprint(context);
}

function mkCountryInputComponent(context: Context): DynamicUIComponent {
  const { getPhrase, getBuffer, getAcceptedCountries, setBuffer } = context;
  const acceptedCountries = getAcceptedCountries();
  const address = getBuffer();
  const { country } = address;
  const setValue = (v: Address["country"]) => {
    if (v === country) return;
    address.longForm = "";
    address.streetName = null;
    address.postalCode = null;
    address.streetType = null;
    address.state = null;
    address.streetNumber = null;
    address.suburb = null;
    address.town = null;
    address.unitNumber = null;
    address.country = v;
    setBuffer(address);
  };
  return new DynamicUIComponent({
    tag: "country-input",
    classes: ["country", "mb-6"],
    attrs: {
      label: getPhrase("address_manual_input_screen.country_label", {
        isMandatory: true,
      }),
      disabled: acceptedCountries.length <= 1,
      options: acceptedCountries,
      filterable: true,
      foreign: country !== "AUS",
      placeholder: getPhrase("address_manual_input_screen.country_placeholder"),
      value: country,
    },
    listeners: {
      input: setValue,
    },
  });
}
