<template lang="pug">
  generic-input( v-if="foreign" v-bind="textAttrs.attrs" v-on="textAttrs.listeners" :validation="validation")
  select-option( v-else v-bind="selectAttrs.attrs" v-on="selectAttrs.listeners" :tabindex="tabindex" :validation="validation")
    template( v-if="$slots.default" ): slot
</template>

<script>

import { nzLocalities } from "@/utils/nzLocalities";
import {
  SelectOption,
  GenericInput,
} from "@frankieone/shared";

export default {
  name: "NZLocalityInput",
  props: {
    foreign: Boolean,
    value: String,
    disabled: Boolean,
    native: Boolean,
    tabindex: Number,
    validation: {
      type: [Boolean, String],
      default: true,
    },
    noBottomPadding: {
      type: Boolean,
      default: true,
    },
    dropUp: {
      type: Boolean,
      default: false,
    }
  },
  components: { SelectOption, GenericInput },
  computed: {
    attrs() {
      return {
        attrs: {
          ...this.$attrs,
          value: this.value,
          disabled: this.disabled,
          native: this.native,
          noBottomPadding: this.noBottomPadding,
        },
        listeners: {
          ...this.$listeners,
          input: (v) => this.$emit("input", v),
        },
      };
    },
    textAttrs() {
      return this.attrs;
    },
    selectAttrs() {
      return {
        attrs: {
          ...this.attrs.attrs,
          options: this.options,
          dropUp: this.dropUp,
        },
        listeners: {
          ...this.attrs.listeners,
        },
      };
    },
    options() {
      let states = nzLocalities.map((locality) => {
        return {
          label: locality,
          value: locality
        };
      });
      return states.sort((a, b) => a.label.localeCompare(b.label));
    },
  },
};
</script>
