import { DynamicUIComponent, UIBlueprint } from "@frankieone/shared";
import { mkGenericAddressBlueprint } from "./generic-split-blueprint";
import { Context } from "./manual-blueprint";
import { WIDTH_SIZES } from "@/plugins/initialiser";

export function mkAUSAddressBlueprint(context: Context) {
  const genericBlueprint = mkGenericAddressBlueprint(context);

  const stateChild = genericBlueprint.getChild("state") as DynamicUIComponent;
  const postalCodeChild = genericBlueprint.getChild(
    "postalCode"
  ) as DynamicUIComponent;

  // set state tag to state-input
  stateChild.setTag("state-input");
  stateChild.setAttr("foreign", false);
  stateChild.setAttr("dropUp", context.getWidthClass() === WIDTH_SIZES.MOBILE);

  postalCodeChild.setAttr("mask", "####");

  genericBlueprint.setChild("state", stateChild);
  genericBlueprint.setChild("postalCode", postalCodeChild);

  genericBlueprint.removeChild("suburb");

  return genericBlueprint;
}
export function mkNZLAddressBlueprint(context: Context): UIBlueprint {
  const genericBlueprint = mkGenericAddressBlueprint(context);

  genericBlueprint.removeChild("state");

  if (context.nzLocalityDropdown) {
    const townChild = genericBlueprint.getChild("town") as DynamicUIComponent;
    townChild.setTag("nz-locality-input");
    townChild.setAttr("filterable", true);
  }

  return genericBlueprint;
}
