





import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Address, UIBlueprint } from "@frankieone/shared";
import { snapshot } from "@/utils/stringify";
import {
  Context,
  mkManualAddressBlueprint,
  setup,
} from "../../personal/address/manual-blueprint";
import { resolveAcceptedCountriesConfigurationObject } from "@/utils/resolveAcceptedCountries";

@Component({
  name: "ManualAddressInput",
})
export default class ManualAddressInput extends Vue {
  @Prop() value: Address;

  @Prop() acceptedCountries: string[];

  snapshot = "";

  buffer: Address = new Address();

  get blueprintContext(): Context {
    return {
      getWidthClass: () => this.responsiveClass,
      getCompleteAddress: () => this.value,
      getAcceptedCountries: () => this.acceptedCountriesFromConfiguration,
      setCompleteAddress: (ad: Address) => this.$emit("input", ad),
      getPhrase: this.getPhrase,
      getBuffer: () => this.buffer,
      setBuffer: (a: Address) => (this.buffer = a),
      nzLocalityDropdown: this.$store.getters.config('nzLocalityDropdown')
    };
  }

  get acceptedCountriesFromConfiguration(): string[] {
    const addressConfiguration = this.$store.getters.config("requestAddress");

    return resolveAcceptedCountriesConfigurationObject(
      addressConfiguration,
      this.acceptedCountries as string[]
    );
  }

  get blueprint(): UIBlueprint {
    return mkManualAddressBlueprint(this.blueprintContext);
  }

  get liveApplicantSnapshot(): string {
    return snapshot(this.value);
  }

  get hasChanged(): boolean {
    return this.snapshot !== this.liveApplicantSnapshot;
  }

  // The following function is from the shared library
  // https://github.com/frankieone/frontend-shared-library/blob/main/src/utils/address.ts
  isEmptyAddress(address: Address): boolean {
    const { country, suburb, town, streetName } = address;
    const isEmpty = (requireList) =>
      requireList.some((field) => !Boolean(address[field]));

    switch (country) {
      case "AUS":
        const suburbOrTown = Boolean(suburb || town);
        return !suburbOrTown || !["streetName", "state", "streetNumber", "country", "postalCode"].every((f) => Boolean(address[f]));
      case "NZL":
        return isEmpty(["streetName", "streetNumber", "postalCode"]);
      case "THA":
        return isEmpty(["streetName", "town"]);
      case "IDN":
        return isEmpty(["streetName", "town"]);
      case "SGP":
        return isEmpty(["streetName", "postalCode", "streetNumber"]);
      default:
        return !((suburb || town || streetName) && country);
    }
  }

  get isAllFilled(): boolean {
    const { buffer, value } = this;
    const isFilled = (a: Address) =>
      !this.isEmptyAddress(a) || a.hasExplicitLongForm();
    return isFilled(value) && isFilled(buffer);
  }

  get allPropsToWatch() {
    return { value: this.value, acceptedCountries: this.acceptedCountries };
  }

  get selectedCountry(): string {
    return this.value?.country ?? "";
  }

  @Watch("allPropsToWatch", { immediate: true, deep: true })
  onValueChanged() {
    setup(this.blueprintContext);
    this.onIsAllFilledChanged();
  }

  @Watch("hasChanged")
  onHasChangedChanged() {
    this.$emit("hasChanged", this.hasChanged);
  }

  @Watch("isAllFilled")
  onIsAllFilledChanged() {
    this.$emit("isAllFilled", this.isAllFilled);
  }

  created() {
    this.$emit("hasChanged", false);
  }
}
