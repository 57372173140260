export const nzLocalities = [
  "Northland",
  "Auckland",
  "Bay of Plenty",
  "Gisborne",
  "Hawke's Bay",
  "Taranaki",
  "Manawatu-Wanganui",
  "Wellington",
  "West Coast",
  "Canterbury",
  "Otago",
  "Southland",
  "Tasman",
  "Nelson",
  "Marlborough",
  "Waikato"
] as const;
