import { DocumentType } from "@frankieone/shared";
import phrases from "@/locales/phrases.json";
import { getTypedDocConfigDefaults } from "./documentFactory";

const objectifyIdTypes = (arr: IdType[]) =>
  arr.map((type: IdType) => getTypedDocConfigDefaults(type));

export const configDefaults: IWidgetConfiguration = {
  mode:
    process.env.NODE_ENV &&
    ["production", "demo", "development"].includes(process.env.NODE_ENV)
      ? (process.env.NODE_ENV as IWidgetConfiguration["mode"])
      : "production",
  documentTypes: objectifyIdTypes([
    "PASSPORT",
    "DRIVERS_LICENCE",
    "NATIONAL_HEALTH_ID",
    "NATIONAL_ID",
  ]),
  welcomeScreen: {
    htmlContent: true,
    ctaText: "Start Identity Verification",
  },
  maxAttemptCount: 5,
  successScreen: {
    ctaUrl: null,
    ctaText: "Continue to My Account",
  },
  failureScreen: {
    ctaUrl: null,
    ctaText: "Contact Us",
  },
  progressBar: true,
  checkProfile: process.env.VUE_APP_CHECK_PROFILE ?? "auto",
  googleAPIKey: false,
  acceptedCountries: ["AUS"],
  frankieBackendUrl: process.env.VUE_APP_FRANKIE_API_URL as string,
  ageRange: [18, 125],
  organisationName: "Organisation",
  dateOfBirth: {
    type: "gregorian",
  },
  idScanVerification: false,
  pendingScreen: {
    htmlContent: null,
    ctaActions: [],
  },
  consentText: null,
  requestAddress: true,
  documentUploads: false,
  lazyIDCheck: false,
  requestID: true,
  phrases,
  disableThirdPartyAnalytics: false,
  injectedCss: "",
  saveOnly: false,
  injectedCssTagID: null,
  enableDeviceCharacteristics: false,
  preload: {
    email: null,
    phone: null,
  },
  nzLocalityDropdown: false
};

export const DOCUMENT_UPLOADS_DEFAULT: DocumentUploadConfig = {
  uploads: [
    {
      title: "Proof of Address",
      types: [DocumentType.BANK_STATEMENT, DocumentType.UTILITY_BILL],
    },
  ],
};
