import { countryData } from "@frankieone/shared";
import { isObject } from "lodash";
import { SUPPORTED_DOCUMENTS } from "../documentFactory";
import validateDocumentUploadsConfig from "./validateDocumentUploadsConfig";

const supportDOB: TDOBTypeSupport[] = ["gregorian", "buddhist"];

type ValidationSchema = {
  [k in keyof IWidgetConfiguration]: any;
};
const isValidAcceptedCountryList = (value: any) => {
  const theType = Array.isArray(value) ? "array" : typeof value;
  const isArray = theType === "array";
  const isNull = value === null;
  const isAll = value === "ALL";
  const isLengthy = isArray && value.length > 0;
  const allChar3 = countryData.map((c) => c.alpha3Code);
  const areAllValid =
    isAll ||
    isNull ||
    (isLengthy && value.every((code) => allChar3.includes(code)));
  return areAllValid;
};
const validationSchema: ValidationSchema = {
  idScanVerification: {
    type: {
      type: (value) => {
        const theType = typeof value;
        const isValueBoolean = theType === "boolean";
        const isObject = theType === "object";
        const isValidObject = (() => {
          if (!isObject) return false;
          const { welcomeScreen, useMobileDevice, useLiveness, injectedCss } =
            value;

          if (!isUndefinedOr("string", injectedCss)) return false;
          if (
            !(
              isUndefinedOr("boolean", welcomeScreen) ||
              isUndefinedOr("object", welcomeScreen)
            )
          ) {
            return false;
          }
          if (!isUndefinedOr("boolean", useMobileDevice)) return false;
          if (!isUndefinedOr("boolean", useLiveness)) return false;
          const { title, content, ctaText } = welcomeScreen ?? {};
          if (!isUndefinedOr("string", title)) return false;
          if (!isUndefinedOr("array", content)) return false;
          if (!isUndefinedOr("string", ctaText)) return false;
          return true;
        })();

        return isValueBoolean || isValidObject;
      },
      message: (value) =>
        `^Option 'idScanVerification' needs to be either a boolean OR an object as specified in the documentation. Received ${typeof value} ${JSON.stringify(
          value
        )}`,
    },
  },
  failureScreen: {
    type: {
      type: (value) => {
        const theType = typeof value;
        const isValueBoolean = theType === "boolean";
        const isObject = theType === "object";
        const isValidObject = (() => {
          if (!isObject) return false;
          const { ctaUrl, ctaText } = value;
          if (ctaUrl && typeof ctaUrl !== "string") return false;
          if (ctaText && typeof ctaText !== "string") return false;
          return true;
        })();

        return isValueBoolean || isValidObject;
      },
      message: (value) =>
        `^Option 'failureScreen' needs to be either a boolean OR an object. Received ${typeof value} ${JSON.stringify(
          value
        )}`,
    },
  },
  mode: {
    inclusion: {
      within: ["demo", "production", "development"],
      message:
        "^Option 'mode' needs to be either 'demo' OR 'production'. Received '%{value}'.",
    },
  },
  dateOfBirth: {
    type: {
      // need to do some thing here
      type: (config: any) => {
        const isValidDOBType = (dobType: TDOBTypeSupport) =>
          supportDOB.includes(dobType);
        return isObject(config) && isValidDOBType((config as TConfigDOB).type);
      },
      message: (value) =>
        `^Option 'dateOfBirth' needs to be an object with support type ${supportDOB}. Received ${typeof value} ${JSON.stringify(
          value
        )}`,
    },
  },
  documentTypes: {
    type: {
      type: (list: any) => {
        const isValueString = (value) => typeof value === "string";
        const isValueInDocTypeArray = (value) =>
          SUPPORTED_DOCUMENTS.includes(value);
        const isCustomDocType = (value: string) => value.startsWith("CUSTOM_");
        const isSupportedDocType = (value: string) =>
          isValueInDocTypeArray(value) || isCustomDocType(value);

        const isValueDocType = (value: TDocumentTypes[0]) => {
          const typeOfDocumentConfig = typeof value;
          let documentType;
          if (typeOfDocumentConfig === "string") {
            documentType = value;
          } else if (typeOfDocumentConfig === "object") {
            documentType = (value as UserDocConfig).type;
          }
          return (
            isValueString(documentType) && isSupportedDocType(documentType)
          );
        };
        const validateAcceptedCountries = (documentList) => {
          documentList.forEach(({ type, acceptedCountries }) => {
            // acceptedCountries validation check
            if (
              typeof acceptedCountries === "undefined" ||
              acceptedCountries === "ALL" ||
              isValidAcceptedCountryList(acceptedCountries)
            ) {
              return;
            }
            // when invalid acceptedCountries
            console.error(
              `Some of the country codes provided in 'acceptedCountries' for '${type}' under 'documentTypes' are not supported.\n${JSON.stringify(
                acceptedCountries,
                null,
                4
              )}`
            );
          });
        };
        validateAcceptedCountries(list);
        const listIsArray = Array.isArray(list);
        const allItemsAreDocTypes = list.every(isValueDocType);
        return listIsArray && allItemsAreDocTypes;
      },
      message: (value) => {
        type HasType = string | { type: string };
        const typefy = (type: HasType) =>
          typeof type === "string" ? type : `{${type.type}}`;
        const received = Array.isArray(value)
          ? `[${value.map(typefy).join(", ")}]`
          : value;
        return `^Option 'documentTypes' needs to be an array of document types, as specified in the documentation. Received ${received}.`;
      },
    },
  },
  welcomeScreen: {
    type: {
      type: (value: any) => {
        const theType = typeof value;
        const isBoolean = theType === "boolean";
        const isObject = theType !== "string" && theType === "object";
        return isBoolean || isObject;
      },
      message: (value) =>
        `^Option 'welcomeScreen' needs to be either a boolean or an object of options for the Welcome Screen. Received ${typeof value} ${JSON.stringify(
          value
        )}.`,
    },
  },
  successScreen: {
    type: {
      type: (value: any) => {
        const theType = typeof value;
        const isObject = theType !== "string" && theType === "object";
        return isObject;
      },
      message: (value) =>
        `^Option 'successScreen' needs to be an object of options for the Success Screen. Received ${typeof value} ${JSON.stringify(
          value
        )}.`,
    },
  },
  pendingScreen: {
    type: {
      type: (value: any) => {
        const theType = typeof value;
        const isFalse = value === false;
        const isObject = theType !== "string" && theType === "object";
        return isObject || isFalse;
      },
      message: (value) =>
        `^Option 'pendingScreen' needs to be either boolean false or an object of options for the Pending Screen. Received ${typeof value} ${JSON.stringify(
          value
        )}.`,
    },
  },
  maxAttemptCount: {
    type: {
      type: (value: any) => {
        const theType = typeof value;
        const isNumeric = theType === "number";
        const isGreaterThanZero = value > 0;
        return isNumeric && isGreaterThanZero;
      },
      message: (value) =>
        `^Option 'maxAttemptCount' needs to be a number greater than 0. Received ${typeof value} ${JSON.stringify(
          value
        )}.`,
    },
  },
  progressBar: {
    type: {
      type: (value: any) => {
        const theType = typeof value;
        const isBoolean = theType === "boolean";
        return isBoolean;
      },
      message: (value) =>
        `^Option 'progressBar' needs to be a boolean. Received ${typeof value} ${JSON.stringify(
          value
        )}.`,
    },
  },
  requestAddress: {
    type: {
      type: (value: any) => {
        const theType = typeof value;
        const isBoolean = theType === "boolean";

        return isBoolean || isValidAcceptedCountryList(value.acceptedCountries);
      },
      message: (value) =>
        `^Option 'requestAddress' needs to be a boolean or the object { acceptedCountries: char3[] | 'ALL' }. Received ${typeof value} ${JSON.stringify(
          value
        )}.`,
    },
  },
  requestID: {
    type: {
      type: (value: any) => {
        const theType = typeof value;
        const isBoolean = theType === "boolean";
        return isBoolean;
      },
      message: (value) =>
        `^Option 'requestID' needs to be a boolean. Received ${typeof value} ${JSON.stringify(
          value
        )}.`,
    },
  },
  checkProfile: {
    type: {
      type: (value: any) => {
        const theType = typeof value;
        const isString = theType === "string";
        return isString;
      },
      message: (value) =>
        `^Option 'checkProfile' needs to be a string. Received ${typeof value} ${JSON.stringify(
          value
        )}.`,
    },
  },
  injectedCss: {
    type: {
      type: (value: any) => {
        const theType = typeof value;
        const isString = theType === "string";
        return isString;
      },
      message: (value) =>
        `^Option 'injectedCss' needs to be a string. Received ${typeof value} ${value}.`,
    },
  },
  googleAPIKey: {
    type: {
      type: (value: any) => {
        const theType = typeof value;
        const isString = theType === "string";
        const isFalse = value === false;
        return isString || isFalse;
      },
      message: (value) =>
        `^Option 'googleAPIKey' needs to be either the boolean false or a string with a valid Google API key. Received ${typeof value} ${JSON.stringify(
          value
        )}.`,
    },
  },
  acceptedCountries: {
    type: {
      type: (value: any) => {
        return isValidAcceptedCountryList(value);
      },
      message: (value) => {
        const isArray = Array.isArray(value);
        const theType = isArray ? "array" : typeof value;
        const formatted = isArray ? `[${value.join(", ")}]` : value;
        return `Option 'acceptedCountries' needs to be an array of at least one valid char3 country code, or the string 'ALL', as specified in the documentation. Received ${theType} ${formatted}`;
      },
    },
  },
  ageRange: {
    type: {
      type: (value: any) => {
        const theType = Array.isArray(value) ? "array" : typeof value;
        const isArray = theType === "array";
        const isLengthTwo = isArray && value.length > 0 && value.length === 2;
        const isFieldsNumeric = value.every((num) => typeof num === "number");
        const isFieldsGreaterThanZero = value.every((num) => num > 0);
        const isMaxAgeGreaterThanMinAge = value[1] > value[0];
        return (
          isArray &&
          isLengthTwo &&
          isFieldsNumeric &&
          isFieldsGreaterThanZero &&
          isMaxAgeGreaterThanMinAge
        );
      },
      message: (value) => {
        const isArray = Array.isArray(value);
        const received = isArray ? `[${value.join(", ")}]` : value;
        const theType = isArray ? "array" : typeof value;
        return `^Option 'ageRange' needs to be an array of numbers whose values are greater than 0 and maximum age greater than minimum age. Received ${theType} ${received}.`;
      },
    },
  },
  frankieBackendUrl: {
    type: {
      type: (value: any) => {
        if (process.env.NODE_ENV) {
          if (["development", "demo"].includes(process.env.NODE_ENV))
            return true;
        }
        const isFrankieUrl = (str: string) => {
          if (typeof str !== "string") return false;
          let url: URL;
          try {
            url = new URL(str);
          } catch (e) {
            return false;
          }
          const validProtocol = url.protocol.match(/https/);
          const isFrankieDomain = url.host.match(/frankiefinancial\.(io|com)/);
          return validProtocol && isFrankieDomain;
        };
        return isFrankieUrl(value);
      },
      message: (value) => {
        const isArray = Array.isArray(value);
        const theType = isArray ? "array" : typeof value;
        const formatted = isArray ? `[${value.join(", ")}]` : value;
        return `Option 'frankieBackendUrl' needs to be a valid Frankie Financial https URL. Received ${theType} ${formatted}`;
      },
    },
  },
  organisationName: {
    type: {
      type: (value: any) => {
        const theType = typeof value;
        const isString = theType === "string";
        return isString;
      },
      message: (value) =>
        `Option 'organisationName' needs to be a string with a valid Organisation Name. Received ${typeof value} ${JSON.stringify(
          value
        )}.`,
    },
  },
  consentText: {
    type: {
      type: (value: any) => {
        const theType = typeof value;
        const isString = theType === "string";
        const isNull = theType === null;
        return isString || isNull;
      },
      message: (value) =>
        `^Option 'consentText' needs to be a valid text string. Received ${typeof value} ${JSON.stringify(
          value
        )}`,
    },
  },
  lazyIDCheck: {
    type: {
      type: (value: any) => {
        const theType = typeof value;
        const isBoolean = theType === "boolean";
        return isBoolean;
      },
      message: (value) =>
        `^Option 'lazyIDCheck' needs to be a boolean. Received ${typeof value} ${JSON.stringify(
          value
        )}.`,
    },
  },
  phrases: {
    type: {
      type: (value: any) => {
        const theType = typeof value;
        const isNull = theType === null;
        const isObject = theType !== "string" && theType === "object";
        return isObject || isNull;
      },
      message: (value) =>
        `^Option 'phrases' needs to be either null or an object of options for the Phrases. Received ${typeof value} ${JSON.stringify(
          value
        )}.`,
    },
  },
  disableThirdPartyAnalytics: {
    type: {
      type: (value: any) => {
        const theType = typeof value;
        const isBoolean = theType === "boolean";
        return isBoolean;
      },
      message: (value) =>
        `^Option 'disableThirdPartyAnalytics' needs to be a boolean. Received ${typeof value} ${value}.`,
    },
  },
  saveOnly: {
    type: {
      type: (value: any) => {
        const theType = typeof value;
        const isBoolean = theType === "boolean";
        return isBoolean;
      },
      message: (value) =>
        `^Option 'saveOnly' needs to be a boolean. Received ${typeof value} ${value}.`,
    },
  },
  documentUploads: {
    type: {
      type: validateDocumentUploadsConfig,
      message: (value) =>
        `^Option 'documentUploads' needs to be a boolean or config object specified in documentation. Received ${typeof value} ${JSON.stringify(
          value
        )}.`,
    },
  },
  injectedCssTagID: {},
  enableDeviceCharacteristics: {
    type: {
      type: (value: any) => typeof value === "boolean",
      message: (value) =>
        `^Option 'enableDeviceCharacteristics' needs to be a boolean. Received ${typeof value} ${value}.`,
    },
  },
  preload: {
    type: {
      type: (value: any) => typeof value === "object",
      message: (value) =>
        `^Option 'preload' needs to be an object and follows the format as specified in the documentation. Received ${typeof value} ${JSON.stringify(
          value
        )}.`,
    },
  },
  nzLocalityDropdown: {
    type: {
      type: (value: any) => typeof value === "boolean",
      message: (value) =>
        `^Option 'nzLocalityDropdown' needs to be a boolean. Received ${typeof value} ${value}.`,

    }
  }
};

const isUndefinedOr = (type: string, value: any) => {
  const theTypeOfValue = typeof value;
  if (theTypeOfValue === "undefined") return true;
  if (type === "array") return Array.isArray(value);
  return type === theTypeOfValue;
};

export default validationSchema;
